export function detail(controller , scene){
	if(document.getElementById('site-estate-detail')){
		const galleryThumbs = new Swiper('.gallery-thumbs', {
		spaceBetween: 15,
		slidesPerView: 5,
		freeMode: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		});
		const galleryTop = new Swiper('.gallery-top', {
			spaceBetween: 0,
			loop:true,
			thumbs: {
				swiper: galleryThumbs,
			},
		});
	}
}