import {scrolEvents} from './default';
import {index} from './index';
import {detail} from './detail';

document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
	
	index();
	detail();
	scrolEvents(controller , scene);
});