export function index(){
	
	if(document.getElementById('site-firstview')){
		const swiper = new Swiper('.swiper-firstview',{
			slidesPerView: 1,
			spaceBetween: 0,
			effect: 'fade',
			speed: 2000,
			loop: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true
			},
		});
	}
	
	if(document.getElementById('swiper-estate')){
		const swiper = new Swiper('#swiper-estate',{
			slidesPerView: 'auto',
			spaceBetween: 0,
			centeredSlides: true,
			speed: 600,
			grabCursor: true,
			loop: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
		});
	}
	
}